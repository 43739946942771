
  import { defineComponent, ref, computed, getCurrentInstance } from "vue"
  import TitleActions from "@/components/shared/TitleActions.vue";
  import LoadingOverlay from "@/components/shared/LoadingOverlay.vue";
  import { Ticket } from "@/models/tickets";
  import { Subscription } from "@/models/subscriptions";
  import { SingleResourceDoc } from '@/models/jsonapi'
  import { FileMetaData as FMDModel } from "@/models/file_meta_datas";
  import { ResourceObject, RelationshipsWithData, ResourceIdentifierObject } from "@/models/jsonapi";
  import {apiDataMorpher, extractRelationshipIds, modelTranslation} from "@/utils/apiDataHelper";
  import { confirm, prompt } from '@/utils/interactionModals'
  import { resourceUrl } from "@/utils/dataExtractors";
  import TicketMessage from "@/components/shared/TicketMessage.vue"
  import FileMetaData from "@/components/shared/FileMetaData.vue"

  interface Props {
    id: string,
    action?: string
  }

  interface Sub {
    active: boolean,
    displayType: string,
    ids: string[],
  }

  export default defineComponent({
    components: {
      TitleActions,
      TicketMessage,
      LoadingOverlay,
      FileMetaData,
    },
    props: {
      id: {
        type: String,
        required: true
      },
      action: {
        type: String
      }
    },
    setup(props: Props, { emit }) {
      const root = getCurrentInstance().proxy
      const ticket = computed<ResourceObject<string, Ticket>>(() => root.$store.state.tickets.element)
      const ticketSendModal = ref()
      const editId = ref('')
      const editFilename = ref('')
      const showLoading = ref(false)
      const ticketMessage = ref('')
      const ticketSubs = ref<Sub[]>([])

      const refreshData = async () => {
        const ticketQuery = { include: 'file_meta_datas' }
        await root.$store.dispatch('tickets/get', { id: props.id, queryParams: ticketQuery })
        getSubscriptions()
      }

      const getSubscriptions = async () => {
        const subIds = extractRelationshipIds({ data: [ticket.value] }, 'subscriptions')
        const subsQuery = {
          'filter[id]': subIds.join(','), 'page[number]': 1, 'page[size]': subIds.length, include: 'proto_user'
        }
        await root.$store.dispatch('subscriptions/load', subsQuery)
        const groupedSubs = root.$store.state.subscriptions.data.reduce(subGrouper,{})
        ticketSubs.value = Object.keys(groupedSubs).map((type) => {
          const subs = groupedSubs[type] as ResourceObject<string, Subscription>[]
          const active = (subs.map((s)=>s.attributes?.active).indexOf(true) > -1)
          const ids = (subs.map((s)=>s.id)) as string[]
          return {
            active: active,
            displayType: displayType(type),
            ids: ids,
          }
        });
      }
      refreshData()

      const subGrouper = (res, sub) => {
        const type = sub.attributes?.proto_user?.attributes?._type
        if (!res[type]) {res[type] = []}
        res[type].push(sub)
        return res
      }

      const ticketMessages = computed(() => {
        if (!ticket.value) return []
        const messages = ticket.value.attributes?.messages as any[]
        return messages.sort((a, b) => (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any))
      })

      // const performTicketAction = async () => {
      //   console.log('pa: ',props.action)
      //   if (props.action === 'view') {
      //     await createNewTicketMessage()
      //   } else if (props.action === 'archive') {
      //     await archiveTicket()
      //   }
      //   emit('done', true)
      // }

      const createNewTicketMessage = async () => {
        if (ticketMessage.value.length > 0) {
          // Create the new message
          const data = {attributes: {messages: [{ payload: ticketMessage.value }]}}
          showLoading.value = true
          try {
            await root.$store.dispatch('tickets/edit', { id: props.id, body: data })
          } finally {
            ticketMessage.value = ''
            refreshData()
            showLoading.value = false
          }
          //await apiClient.post(ticket.links.newMessage, {message: { payload: ticketMessage.value }})
        }
      }

      // const archiveTicket = async () => {
      //   //await TasksRepository.delete(props.task.id)
      // }
      const markRead = () => {
        showLoading.value = true
        root.$store.dispatch('tickets/edit', {id: props.id, body: {usecase: 'mark_read'}})
          .finally(() => {
            showLoading.value = false
          })
      }

      const titleActions = ref([
        {
          title: '"Dringend" Umschalten',
          icon: 'exclamation-triangle',
          tooltip: 'Beim Ticket und allen vorhandenen Tasks wird "Dringend" umgestellt',
          onClick: async () => {
            if (typeof(ticket.value?.attributes?.priority) === 'boolean') {
              const data = {attributes: {priority: !ticket.value.attributes.priority}}
              await root.$store.dispatch('tickets/edit', { id: props.id, body: data })
              refreshData()
            }
          },
        },
        {
          title: 'Gelesen!',
          icon: ['fad', 'check-circle'],
          tooltip: 'Als gelesen markieren (Meine Benachrichtigung löschen)',
          onClick: markRead,
        },
        // {
        //   title: 'Mail an Kunde',
        //   icon: 'envelope',
        //   tooltip: 'Mail an Kunden',
        //   onClick: () => {
        //     showLoading.value = true
        //     setTimeout(function(){ showLoading.value = false }, 3000)
        //   }
        // },
        {
          title: 'Archivieren',
          icon: 'archive',
          onClick: () => alert('Archivieren')
        },
      ])

      const displayType = (type: string) => {
        const out = {
          'Seller': 'Vertrieb',
          'Assistant': 'Vertrieb',
          'Internal': 'Innendienst',
          'Provider': 'Agentur',
        }[type]
        return out ? out : 'Unbekannt'
      }

      const getType = (sub: any) => displayType(sub.attributes.proto_user.attributes['_type'])

      const subTypeClicked = (sub: Sub) => {
        sub.ids.forEach(async (id) => {
          const updateData = { attributes: { active: !sub.active } }
          showLoading.value = true
          try {
            await root.$store.dispatch('subscriptions/edit', { id: id, body: updateData })
          } finally {
            sub.active = !sub.active
            showLoading.value = false
          }
        })
      }

      const updateTicket = (data: any) => {
        showLoading.value = true
        root.$store.dispatch('tickets/edit', { id: props.id, body: data }).then(() => refreshData()).finally(() => {
          showLoading.value = false
        })
      }

      const messageCountInfo = computed(() => {
        const c = ticketMessages.value.length
        const w = c === 1 ? 'Nachricht' : 'Nachrichten'
        return `(${c} ${w})`
      })

      const switchMailIncluded = (messageId: string) => {
        let messages: any[] = []
        if (ticket.value.attributes?.messages) {
          messages = [...ticket.value.attributes.messages]
        }
        const messageWithId = messages.find(message => message.id === messageId)
        messageWithId.mailIncluded = !messageWithId.mailIncluded
        const updateData = { attributes: { messages: messages } }
        updateTicket(updateData)
      }

      return {
        ticket,
        ticketSubs,
        modelTranslation,
        createNewTicketMessage,
        titleActions,
        ticketMessages,
        ticketMessage,
        getType,
        subTypeClicked,
        showLoading,
        messageCountInfo,
        resourceUrl,
        refreshData,
        updateTicket,
        switchMailIncluded,
        ticketSendModal
      }
    }
  })
